<template>
<div>
  <!-- <v-switch :true-value="true" :false-value="false" v-model="debug" label="Debug"/> -->
  <Survey :questionSetID="questionSetID" :debug="debug" :questionSet="questionSet" @submit="submit"/>
</div>
</template>

<script>
import Survey from '@/components/Questions/QuestionSet'

export default {
  name: "developerSurvey",
  components: {
    Survey
  },
  created: function(){
    let key = this.questionSetID
    let storage = window.localStorage;
    let cache = storage.getItem('survey_editor_cache');
    if(cache){
        cache = JSON.parse(cache);
        if(cache[key]){
            this.questionSet = cache[key]
        }
    }

  },
  data: function(){
    return {
      questionSet: null,
      form: {
        survey: {}
      }
    }
  },
  methods: {
    submit: function(data){
      console.log('developerSurvey - submit',data)
      this.form.survey = data
    }
  },
  computed: {
    debug: function(){
      return this.$store.getters.debug
    },
      questionSetID: function(){
        return this.$route.params.questionSet
      },
  },
  watch: {}

}
</script>

<style scoped>


</style>
